import { reactive, ref } from "vue";

import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Feature from "ol/Feature";
import Icon from "ol/style/Icon";
import GeoJSON from "ol/format/GeoJSON";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import { transform } from "ol/proj";

import { params, getProject, getYear, getVt, format, getDistanceFromLatLonInMetres } from "@/stores/var";
import { layers, mapPanelOne, updateContext } from "@/stores/map";

export const isLoading = ref(false);
export const isPinOn = ref(false);
export const isShowAllSpot = ref(true);
export const isMapMeasurementOn = ref(false);
export const isPanoramaInfoOn = ref(false);
export const showMapSpotPopup = ref(false);
export const isUpdateMapspot = ref(false);
export const notes = ref("");

let project = params.project;
let year = params.year;
let vt = params.vt;

export let camera;
export let renderer;
export let scene;
let geometry, material, mesh;
export let container;
export let other_panos_group = null;
let horizon = null;
let spotProto = null;
let spotProtoMeasurement = null;
let otherPanosMaterial = null;
let otherPanosSelectedMaterial = null;
let locationSpot1 = null;
let locationSpot2 = null;
let locationx = null;
export let plane_surf = null;
export let raycaster = null;
export let mouse = null;
let panoLink = null;
let url_backend = "https://backend.observer.co.id";
let url_admin = "https://login.observer.co.id/";
let widthPanel = 0;
let heightPanel = 0;
let current_pano;
export let panorama_data;
export let spotGroup = null;
export let panoTagsGroup, contextGroup;
let spotGroupMeasurement;
export let gridGeometry, gridMaterial, gridMesh;
export let heading, pano_note, panoTrack, filename, pano_key, pano_lat, pano_lon, utm_code, utm_srid, utm_x, utm_y, creator;
let pitch, roll, height_from_ground, height_from_ground_reset, roll_reset, track_reset;
export const coordinates = reactive({
  lat: 0,
  lon: 0,
});
let lat = coordinates.lat;
let lon = coordinates.lon;
let isUserInteracting = false;
let onMouseDownMouseX = 0;
let onMouseDownMouseY = 0;
let onMouseDownLon = 0;
let onMouseDownLat = 0;
let pano_x, pano_y;
let cursor_x = 0;
let cursor_y = 0;
let pano_utm_code, pano_utm_srid;
let utm_proj = "";
let selected_link;
let sourcePanorama = null;
export const lonlat = ref(null);
let arrSpotMeasurement = 0;
let spotMeasurement = [];
let point = [];
let line = null;
export const measureDistance1 = ref(null);

export const mapSpotData = reactive({
  img_key: null,
  img_lon: null,
  img_lat: null,
  spot_x: null,
  spot_y: null,
  spot_z: null,
});

const getFov = () => {
  return Math.floor((2 * Math.atan(camera.getFilmHeight() / 2 / camera.getFocalLength()) * 180) / Math.PI);
};

const clickZoom = (value, zoomType) => {
  if (value >= 20 && zoomType === "zoomIn") {
    return value - 5;
  } else if (value <= 75 && zoomType === "zoomOut") {
    return value + 5;
  } else {
    return value;
  }
};

export const zoomInFunction = (e) => {
  const fov = getFov();
  camera.fov = clickZoom(fov, "zoomIn");
  camera.updateProjectionMatrix();
};

export const zoomOutFunction = (e) => {
  const fov = getFov();
  camera.fov = clickZoom(fov, "zoomOut");
  camera.updateProjectionMatrix();
};

export function viewFixPanorama(correction) {
  camera.position.set(correction.dy, correction.dh - height_from_ground, correction.dx);
  mesh.rotation.x = (Math.PI * correction.dr) / 180;
  mesh.rotation.z = (Math.PI * correction.dp) / 180;
  panoTrack = correction.dt;
  other_panos_group.rotation.y = (Math.PI * panoTrack) / 180;
  spotGroup.rotation.y = (Math.PI * panoTrack) / 180;
  panoTagsGroup.rotation.y = (Math.PI * panoTrack) / 180;
  contextGroup.rotation.y = (Math.PI * panoTrack) / 180;
}

export let initPano = function () {
  widthPanel = document.querySelector("#panorama").offsetWidth;
  heightPanel = document.querySelector("#panorama").offsetHeight;
  container = document.querySelector("#panorama");
  other_panos_group = new THREE.Object3D();

  let horizon_geometry = new THREE.EdgesGeometry(new THREE.CircleGeometry(450, 50));
  let horizon_material = new THREE.LineDashedMaterial({
    color: "orange",
    linewidth: 1,
    scale: 1,
    dashSize: 5,
    gapSize: 5,
  });

  horizon = new THREE.LineLoop(horizon_geometry, horizon_material);
  horizon.computeLineDistances();
  horizon.rotation.x = -Math.PI / 2;
  horizon.rotation.y = Math.PI;

  const spotGeometry1 = new THREE.CircleGeometry(0.3, 16);
  const spotGeometry2 = new THREE.CircleGeometry(0.1, 16);
  const spotMaterial = new THREE.MeshBasicMaterial({
    color: "red",
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.6,
  });

  const spotObj1 = new THREE.Mesh(spotGeometry1, spotMaterial);
  spotObj1.rotation.x = -Math.PI / 2;

  const spotObj2 = new THREE.Mesh(spotGeometry2, spotMaterial);
  spotObj2.position.set(0, 0.01, 0);
  spotObj2.rotation.x = -Math.PI / 2;

  spotProto = new THREE.Object3D();
  spotProto.add(spotObj1, spotObj2);
  spotProto.position.set(1000, 1000, 1000);

  const spotGeometryMeasurement1 = new THREE.CircleGeometry(0.3, 16);
  const spotGeometryMeasurement2 = new THREE.CircleGeometry(0.1, 16);
  const spotMaterialMeasurement = new THREE.MeshBasicMaterial({
    color: "orange",
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.6,
  });

  const spotObjMeasurement1 = new THREE.Mesh(spotGeometryMeasurement1, spotMaterialMeasurement);
  spotObjMeasurement1.rotation.x = -Math.PI / 2;

  const spotObjMeasurement2 = new THREE.Mesh(spotGeometryMeasurement2, spotMaterialMeasurement);
  spotObjMeasurement2.position.set(0, 0.01, 0);
  spotObjMeasurement2.rotation.x = -Math.PI / 2;

  spotProtoMeasurement = new THREE.Object3D();
  spotProtoMeasurement.add(spotObjMeasurement1, spotObjMeasurement2);
  spotProtoMeasurement.position.set(1000, 1000, 1000);

  otherPanosMaterial = new THREE.MeshBasicMaterial({
    color: 0xf0ff20,
    //wireframe: true,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.4,
  });

  otherPanosSelectedMaterial = new THREE.MeshBasicMaterial({
    color: "yellow",
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.8,
  });

  const locationGeometry = new THREE.CircleGeometry(1.5, 32);
  const locationMaterial = new THREE.MeshBasicMaterial({
    color: "#ffffff",
    //wireframe: true,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.2,
  });

  locationSpot1 = new THREE.Mesh(locationGeometry, locationMaterial);
  locationSpot1.rotation.x = -Math.PI / 2;

  locationSpot2 = new THREE.Mesh(new THREE.CircleGeometry(0.8, 32), locationMaterial);
  locationSpot2.position.set(0, 0.01, 0);
  locationSpot2.rotation.x = -Math.PI / 2;

  let linesGeometry = [];
  const locationLineGeometry = new THREE.BufferGeometry();
  const locationLineMaterial = new THREE.LineDashedMaterial({
    color: 0xffffff,
    linewidth: 4,
    scale: 1,
    dashSize: 0.1,
    gapSize: 0.1,
  });

  linesGeometry.push(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1000, 0));
  locationLineGeometry.setFromPoints(linesGeometry);

  const locationLine = new THREE.Line(locationLineGeometry, locationLineMaterial);
  locationLine.computeLineDistances();

  locationx = new THREE.Object3D();
  locationx.add(locationSpot1, locationSpot2, locationLine);
  locationx.position.set(1000, 1000, 1000);

  const planeGeometry = new THREE.PlaneGeometry(1000, 1000, 100, 100);
  const planeMaterial = new THREE.MeshBasicMaterial({
    color: 0xfff00f,
    transparent: true,
    visible: false,
    side: THREE.DoubleSide,
    opacity: 0.1,
  });

  plane_surf = new THREE.Mesh(planeGeometry, planeMaterial);
  plane_surf.rotation.x = -Math.PI / 2;

  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();

  const panoLinkGeometry = new THREE.CircleGeometry(1.5, 60);
  const panoLinkMaterial = new THREE.LineBasicMaterial({
    color: "yellow",
    linewidth: 2,
  });

  panoLink = new THREE.Line(panoLinkGeometry, panoLinkMaterial);
  panoLink.rotation.x = -Math.PI / 2;

  loadPano(initial_pano());
};

let initial_pano = function () {
  project = getProject();
  year = getYear();
  vt = getVt();

  let ret = "";
  let objauthor = new Object();

  objauthor.Accept = "application/json";
  objauthor.Authorization = "Bearer " + getVt();

  $.ajax({
    type: "GET",
    url: url_backend + "/panoramas/?p=1&page_size=1&project=" + project + "&year=" + year,
    dataType: "json",
    processData: false,
    contentType: false,
    cache: false,
    headers: objauthor,
    enctype: "multipart/form-data",
    async: false,
    success: function (data) {
      if (data.results.length > 0) {
        ret = data.results[0].id;
      }
    },
    error: function (xhr) {
      console.log(xhr);
    },
  });

  return ret;
};

export let loadPano = function (pano_key) {
  isLoading.value = true;
  camera = new THREE.PerspectiveCamera(50, widthPanel / heightPanel, 1, 1100);
  camera.target = new THREE.Vector3(0, 0, 0);
  scene = new THREE.Scene();
  geometry = new THREE.SphereBufferGeometry(500, 25, 25);
  geometry.scale(-1, 1, 1);
  getPanoramaDetails(pano_key).then(panoLoaded);
};

let panoLoaded = function (pano_data) {
  panorama_data = pano_data;
  current_pano = panorama_data;
  const texture_loader = new THREE.TextureLoader();
  texture_loader.load(
    panorama_data["pot_hole_image"] ? panorama_data["pot_hole_image"] : panorama_data["eqimage"],
    function (texture) {
      applyEqimageMapping(texture);
      THREE.Cache.enabled = true;
    },
    function (err) {
      loadPano(initial_pano());
    }
  );

  texture_loader.crossOrigin = "";
};

let getPanoramaDetails = function (pano_key) {
  let promiseObj = new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url_backend + "/panoramas/" + pano_key + "/?format=json", true);
    xhr.setRequestHeader("Authorization", "Bearer " + getVt());
    xhr.send();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let resp = xhr.responseText;
          let respJson = JSON.parse(resp);
          resolve(respJson);
        } else {
          reject(xhr.status);
          console.log("xhr failed");
        }
      }
    };
  });
  return promiseObj;
};

let applyEqimageMapping = function (mapping) {
  if (material) {
    material.dispose();
  }

  material = new THREE.MeshBasicMaterial({
    map: mapping,
    side: THREE.DoubleSide,
  });

  mesh = new THREE.Mesh(geometry, material);
  scene.add(mesh, plane_surf, locationx, horizon);

  panoTagsGroup = new THREE.Group();
  scene.add(panoTagsGroup);

  contextGroup = new THREE.Group();
  scene.add(contextGroup);

  spotGroup = new THREE.Group();
  scene.add(spotGroup);
  spotGroup.add(spotProto);
  spotProto.position.set(5, 5, -2);

  spotGroupMeasurement = new THREE.Group();
  scene.add(spotGroupMeasurement);
  spotGroupMeasurement.add(spotProtoMeasurement);
  spotProtoMeasurement.position.set(5, 5, -2);

  gridGeometry = new THREE.SphereBufferGeometry(450, 40, 20);
  gridMaterial = new THREE.MeshBasicMaterial({
    color: new THREE.Color(0xffff00),
    visible: false,
    side: THREE.DoubleSide,
  });

  gridMesh = new THREE.Mesh(gridGeometry, gridMaterial);
  scene.add(gridMesh);

  if (renderer == undefined) {
    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(widthPanel, heightPanel);
    container.appendChild(renderer.domElement);
  }

  heading = 0;
  pano_note = panorama_data["note"];
  filename = panorama_data["base_name"];
  pano_key = panorama_data["id"];
  pano_lat = panorama_data["lat"];
  pano_lon = panorama_data["lon"];
  utm_code = panorama_data["utm_code"];
  utm_srid = panorama_data["utm_srid"];
  utm_x = panorama_data["utm_x"];
  utm_y = panorama_data["utm_y"];
  creator = panorama_data["creator_name"];

  if (panorama_data["pitch"]) {
    pitch = panorama_data["pitch"];
  } else {
    pitch = 0;
  }

  if (panorama_data["roll"]) {
    roll = panorama_data["roll"];
  } else {
    roll = 0;
  }

  mesh.rotation.y = Math.PI;
  mesh.rotation.z = (Math.PI * pitch) / 180;
  mesh.rotation.x = (Math.PI * roll) / 180;

  if (panorama_data["height_from_ground"]) {
    height_from_ground = panorama_data["height_from_ground"];
  } else {
    height_from_ground = 2;
  }

  plane_surf.position.y = -height_from_ground;

  if (lat && lon) {
    render();
    emitViewChanged();
  }

  panoTrack = heading;

  height_from_ground_reset = height_from_ground;
  roll_reset = roll;
  track_reset = panoTrack;

  updateLocation(pano_key, pano_lon, pano_lat, utm_x, utm_y, utm_code, utm_srid, height_from_ground_reset, heading, roll, pitch, pano_note, creator);
  restoreSpots();
  restoreOtherPanos();
  enableNavigation(true);
  render();
  isLoading.value = false;
};

let updateLocation = function (pano_key, lon, lat, utm_x, utm_y, utm_code, utm_srid, height, heading, roll, pitch, note, creator) {
  pano_key = pano_key;
  pano_x = utm_x;
  pano_y = utm_y;
  pano_lon = lon;
  pano_lat = lat;
  pano_utm_code = utm_code;
  pano_utm_srid = utm_srid;

  setUtmProj(utm_code);
  icon.setGeometry(new Point(transform([lon, lat], "EPSG:4326", "EPSG:3857")));
  setView(lon, lat);
};

let setUtmProj = function (utm_code) {
  let utm_zone = utm_code.slice(0, -1);
  const utm_suffix = utm_code.slice(-1);
  if (!"NPQRSTUVZXY".includes(utm_suffix)) {
    utm_zone += "+south";
  }
  utm_proj = "+proj=utm +zone=%z +datum=WGS84 +units=m +no_defs".replace("%z", utm_zone); // + ("N" == utm_or ? "" : "+south"))
};

let setView = function (lon, lat) {
  mapPanelOne.value.getView().setCenter(transform([lon, lat], "EPSG:4326", "EPSG:3857"));
  mapPanelOne.value.addLayer(markerVector);
  markerVector.setZIndex(1000);
};

function createStyle(feature) {
  let pathIcon = "";
  const coordsx = feature.getGeometry().getCoordinates();

  if (coordsx[0] == 0 && coordsx[1] == 0) {
    pathIcon = "/null.png";
  } else {
    switch (feature.get("name")) {
      case "pano":
        pathIcon = "/pointer/viewpoint_dx.png";
        break;
      case "cursor":
        pathIcon = "/pointer/cursor_dx.png";
        break;
    }
  }

  let st = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: "fraction",
      anchorYUnits: "fraction",
      rotateWithView: true,
      rotation: feature.get("rotation"),
      src: pathIcon,
    }),
  });

  return st;
}

export const cursor = new Feature({
  geometry: new Point([0, 0]),
  name: "cursor",
  rotation: 0,
});

export const icon = new Feature({
  geometry: new Point([0, 0]),
  name: "pano",
  rotation: 0,
});

const markerVector = new VectorLayer({
  source: new VectorSource({
    features: [icon, cursor],
    projection: "EPSG:4326",
  }),
  style: createStyle,
});

let render = function () {
  requestAnimationFrame(render);
  update();
};

let update = function () {
  lat = Math.max(-85, Math.min(85, lat));
  const phi = THREE.Math.degToRad(90 - lat);
  const theta = THREE.Math.degToRad(lon);

  camera.target.x = 500 * Math.sin(phi) * Math.cos(theta);
  camera.target.y = 500 * Math.cos(phi);
  camera.target.z = 500 * Math.sin(phi) * Math.sin(theta);

  camera.lookAt(camera.target);
  renderer.render(scene, camera);
};

export let renderResize = function () {
  let widthPanel = $("#panorama").width();
  let heightPanel = $("#panorama").height();

  if (renderer) {
    renderer.setSize(widthPanel, heightPanel);
  }

  camera.aspect = widthPanel / heightPanel;
  camera.updateProjectionMatrix();
};

let redrawContext = function (context, bool) {
  for (let i = contextGroup.children.length - 1; i >= 0; i--) {
    contextGroup.remove(contextGroup.children[i]);
  }

  scene.remove(contextGroup);

  if (bool) {
    for (let i = 0; i < context.length; ++i) {
      const map_feat = context[i];
      if (map_feat["geometry"]["type"] == "MultiPolygon") {
        console.log("MultiPolygon");
        const vector = [];
        const contextShape = new THREE.BufferGeometry();

        for (let k = 0; k < map_feat["geometry"]["coordinates"][0][0].length; ++k) {
          const vertex = new THREE.Vector3(map_feat["geometry"]["coordinates"][0][0][k][1] - utm_y, -height_from_ground, map_feat["geometry"]["coordinates"][0][0][k][0] - utm_x);

          vector.push(vertex);
          contextShape.setAttribute("position", new THREE.Float32BufferAttribute(vertex, 3));
        }

        const newContextObject = new THREE.Line(contextShape.setFromPoints(vector), contextPolyMaterial);
        contextGroup.add(newContextObject);
      }

      if (map_feat["geometry"]["type"] == "MultiLineString") {
        console.log("MultiLineString");
        const contextShape = new THREE.BufferGeometry();

        for (let k = 0; k < map_feat["geometry"]["coordinates"][0].length; ++k) {
          const vertex = new THREE.Vector3(map_feat["geometry"]["coordinates"][0][k][1] - utm_y, -height_from_ground, map_feat["geometry"]["coordinates"][0][k][0] - utm_x);
          contextShape.setAttribute("position", new THREE.Float32BufferAttribute(vertex, 3));
        }

        const newContextObject = new THREE.Line(contextShape, contextLineMaterial);
        contextGroup.add(newContextObject);
      }

      if (map_feat["geometry"]["type"] == "MultiPoint") {
        console.log("MultiPoint");
        for (let k = 0; k < map_feat["geometry"]["coordinates"].length; ++k) {
          const newContextPoint = contextPointGeometry.clone();

          newContextPoint.position.set(map_feat["geometry"]["coordinates"][k][1] - utm_y, -height_from_ground, map_feat["geometry"]["coordinates"][k][0] - utm_x);

          contextGroup.add(newContextPoint);
        }
      }
    }

    contextGroup.rotation.y = (Math.PI * panoTrack) / 180;
    scene.add(contextGroup);
  }
};

let onDocumentMouseDown = function (event) {
  event.preventDefault();
  isUserInteracting = true;
  onMouseDownMouseX = event.clientX;
  onMouseDownMouseY = event.clientY;
  onMouseDownLon = lon;
  onMouseDownLat = lat;
  update();
};

let onDocumentMouseMove = function (event) {
  if (isUserInteracting === true) {
    lon = (onMouseDownMouseX - event.clientX) * 0.1 + onMouseDownLon;
    lat = (event.clientY - onMouseDownMouseY) * 0.1 + onMouseDownLat;
    emitViewChanged();
  } else {
    if (renderer) {
      const rect = renderer.domElement.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / renderer.domElement.clientWidth) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / renderer.domElement.clientHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      const other_panos_intersect = raycaster.intersectObject(other_panos_group, true);

      if (other_panos_intersect[0]) {
        showCursor(false);
        selected_link = other_panos_intersect[0].object;
        selected_link.material = otherPanosSelectedMaterial;
      } else {
        showCursor(true);
        try {
          selected_link.material = otherPanosMaterial;
        } catch (e) {}
      }

      const plane_intersect = raycaster.intersectObject(plane_surf);

      if (plane_intersect[0]) {
        const back_rotation = (-Math.PI * panoTrack) / 180;
        const back_x = plane_intersect[0].point.z * Math.cos(back_rotation) - plane_intersect[0].point.x * Math.sin(back_rotation);
        const back_y = plane_intersect[0].point.x * Math.cos(back_rotation) + plane_intersect[0].point.z * Math.sin(back_rotation);

        cursorPano(back_x, back_y);
        locationx.position.set(plane_intersect[0].point.x, plane_intersect[0].point.y + 0.1, plane_intersect[0].point.z);
      }
    }
  }
};

let onDocumentMouseUp = function (event) {
  isUserInteracting = false;
};

let onDocumentDblclick = function (event) {
  if (isPinOn.value) {
    const rect = renderer.domElement.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / renderer.domElement.clientHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersect = raycaster.intersectObject(gridMesh);
    const plane_intersect = raycaster.intersectObject(plane_surf);
    let lat_click = 180 * intersect[0].uv.y - 90;
    let lon_click = -(360 * intersect[0].uv.x - panoTrack);

    if (lon_click < 0) {
      lon_click = 360 + lon_click;
    }

    enableNavigation(false);

    showMapSpotPopup.value = true;
    isUpdateMapspot.value = false;
    notes.value = ""; // clear value from notes
    mapSpotData.img_lon = lon_click;
    mapSpotData.img_lat = lat_click;
    mapSpotData.spot_x = plane_intersect[0].point.x;
    mapSpotData.spot_y = plane_intersect[0].point.y;
    mapSpotData.spot_z = plane_intersect[0].point.z;
  } else if (isMapMeasurementOn.value) {
    const rect = renderer.domElement.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / renderer.domElement.clientHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersect = raycaster.intersectObject(gridMesh);
    const plane_intersect = raycaster.intersectObject(plane_surf);
    let lat_click = 180 * intersect[0].uv.y - 90;
    let lon_click = -(360 * intersect[0].uv.x - panoTrack);

    if (lon_click < 0) {
      lon_click = 360 + lon_click;
    }

    // enableNavigation(false);
    console.log(isPinOn.value);
    storeTempMapMeasurement(lon_click, lat_click, plane_intersect[0].point.x, plane_intersect[0].point.y, plane_intersect[0].point.z, intersect);
    mapPanelOne.value.addLayer(spotLayerMeasurement);
  }
};

let onDocumentMouseWheel = function (event) {
  const fov = camera.fov + event.deltaY / 50;
  camera.fov = THREE.Math.clamp(fov, 5, 75);
  camera.updateProjectionMatrix();
};

let onDocumentClick = function (event) {
  const rect = renderer.domElement.getBoundingClientRect();
  mouse.x = ((event.clientX - rect.left) / renderer.domElement.clientWidth) * 2 - 1;
  mouse.y = -((event.clientY - rect.top) / renderer.domElement.clientHeight) * 2 + 1;
  raycaster.setFromCamera(mouse, camera);
  const intersect = raycaster.intersectObjects([other_panos_group, spotGroup], true);

  if (intersect[0]) {
    switch (intersect[0].object.ws_type) {
      case "other pano":
        pano_key = intersect[0].object.ws_pano_key;
        loadPano(pano_key);
        break;
      case "map spot":
        if (isPinOn.value === true) {
          showMapSpotPopup.value = true;
          isUpdateMapspot.value = true;
          mapSpotData.img_key = intersect[0].object.ws_imgObjKey;
          editMapspot(intersect[0].object.ws_imgObjKey);
        } else {
          return;
        }
        break;
    }
  }
};

let editMapspot = function (image_key) {
  let objauthor = new Object();

  objauthor.Accept = "application/json";
  objauthor.Authorization = "Bearer " + getVt();

  $.ajax({
    type: "GET",
    url: url_backend + "/image_objects/" + image_key + "/",
    processData: false,
    contentType: false,
    dataType: "json",
    cache: false,
    headers: objauthor,
    async: false,
    success: function (res) {
      notes.value = res.note;
    },
    error: function (res) {
      console.log(res);
    },
  });
};

export let storeMapSpot = function (img_lon, img_lat, spot_x, spot_y, spot_z) {
  const update_url = url_backend + "/image_objects/";
  const spotLocation_wgs84 = proj4(utm_proj, "EPSG:4326").forward([cursor_x, cursor_y]);
  let objauthor = new Object();

  objauthor.Accept = "application/json";
  objauthor.Authorization = "Bearer " + getVt();

  let note = notes.value;

  $.ajax({
    type: "POST",
    url: update_url,
    headers: objauthor,
    contentType: "application/json",
    data: JSON.stringify({
      panorama: pano_key,
      type: 2,
      img_lon: img_lon,
      img_lat: img_lat,
      lon: spotLocation_wgs84[0],
      lat: spotLocation_wgs84[1],
      utm_x: cursor_x,
      utm_y: cursor_y,
      utm_code: utm_code,
      utm_srid: utm_srid,
      note: note,
      project: getProject(),
    }),
    success: function () {
      isPinOn.value = false;
      showMapSpotPopup.value = false;
      $("#liveToast").addClass("fade show");
      $(".toast-title").text("Saved");
      $(".toast-text").text("Data has been saved successfully");
      restoreSpots();
      updateContext();
    },
    error: function (errormsg) {},
  });

  enableNavigation(true);
};

export let removeMapSpot = function () {
  let xhr = new XMLHttpRequest();
  xhr.open("DELETE", url_backend + "/image_objects/" + mapSpotData.img_key + "/?format=json", true);
  xhr.setRequestHeader("Authorization", "Bearer " + getVt());
  xhr.send();
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status == 204 || xhr.status == 200) {
        $("#liveToast").addClass("fade show");
        $(".toast-title").text("Deleted");
        $(".toast-text").text("Successfully deleted");
        restoreSpots();
        updateContext();
      }
    }
  };
};

export let enableNavigation = function (bool) {
  if (bool) {
    container.addEventListener("mousedown", onDocumentMouseDown, false);
    container.addEventListener("mousemove", onDocumentMouseMove, false);
    container.addEventListener("dblclick", onDocumentDblclick, false);
    container.addEventListener("click", onDocumentClick, false);
    container.addEventListener("wheel", onDocumentMouseWheel, false);
    document.addEventListener("mouseup", onDocumentMouseUp, false);
  } else {
    container.removeEventListener("mousedown", onDocumentMouseDown, false);
    container.removeEventListener("mousemove", onDocumentMouseMove, false);
    container.removeEventListener("dblclick", onDocumentDblclick, false);
    container.removeEventListener("click", onDocumentClick, false);
    container.removeEventListener("wheel", onDocumentMouseWheel, false);
    document.removeEventListener("mouseup", onDocumentMouseUp, false);
  }
};

let showCursor = function (isVisible) {
  locationx.traverse(function (child) {
    child.visible = isVisible;
  });
};

let cursorPano = function (dx, dy) {
  cursor_x = pano_x + dx;
  cursor_y = pano_y + dy;
  cursor.setGeometry(new Point(proj4(utm_proj, "EPSG:3857", [cursor_x, cursor_y])));
};

let rotatePano = function (rot) {
  icon.set("rotation", (rot * Math.PI) / 180);
};

let emitViewChanged = function () {
  let center_view = new THREE.Vector2();
  center_view.x = 0;
  center_view.y = 0;
  raycaster.setFromCamera(center_view, camera);

  let intersect = raycaster.intersectObject(gridMesh);
  let rotation = -(360 * intersect[0].uv.x - panoTrack);

  if (rotation < 0) {
    rotation = 360 + rotation;
  }

  rotation = rotation - 180;
  rotatePano(rotation);
  icon.changed();
};

export let getItems = function (items, filters = "") {
  let promiseObj = new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url_backend + "/" + items + "/" + filters, true);
    xhr.setRequestHeader("Authorization", "Bearer " + getVt());
    xhr.send();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let resp = xhr.responseText;
          let respJson = JSON.parse(resp);

          resolve(respJson);
        } else {
          reject(xhr.status);
          console.log(xhr);
        }
      }
    };
  });

  return promiseObj;
};

let restoreOtherPanos = function () {
  const filters = format("?project=" + getProject() + "&year=" + getYear() + "&dist=25&point=%%,%%", pano_lon, pano_lat);
  getItems("panoramas", filters).then(otherPanosLoaded);
};

let otherPanosLoaded = function (other_panos_data) {
  const otherPanosMaterial = new THREE.MeshBasicMaterial({
    color: 0xf0ff20,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.4,
  });

  scene.remove(other_panos_group);
  other_panos_group = new THREE.Object3D();

  for (var i = 0; i < other_panos_data["count"]; i++) {
    const item = other_panos_data["results"][i];
    if (item) {
      let op_geometry = new THREE.CircleGeometry(1, 32);
      let op_location = new THREE.Mesh(op_geometry, otherPanosMaterial);
      op_location.rotation.x = -Math.PI / 2;
      op_location.position.set(-(utm_y - item.utm_y), -height_from_ground, -(utm_x - item.utm_x));
      op_location.ws_type = "other pano";
      op_location.ws_pano_key = item.id;
      other_panos_group.add(op_location);
    }
  }

  other_panos_group.rotation.y = (Math.PI * panoTrack) / 180;
  scene.add(other_panos_group);
};

let restoreSpots = function (bool = true) {
  removeImgObjs(2);
  if (bool) {
    restoreImgObjs(2);
  }
};

let refreshLabels = function () {
  for (let i = 0; i < panoTagsGroup.children.length; i++) {
    const tagObj = panoTagsGroup.children[i];
    tagObj.refreshLabel();
  }
};

let createLabel = function (type, text) {
  const textElement = document.createElement("div");

  textElement.style.position = "absolute";
  textElement.style.width = "300px";
  textElement.classList.add("overlabel_" + type.toString());
  textElement.innerHTML = text;
  document.getElementById("labels").appendChild(textElement);

  return textElement;
};

let storeTempMapMeasurement = function (img_lon, img_lat, spot_x, spot_y, spot_z, intersect) {
  const spotLocation_wgs84 = proj4(utm_proj, "EPSG:4326").forward([cursor_x, cursor_y]);

  if (spotMeasurement.length > 1) {
    for (var i = spotMeasurement.length; i > 0; i--) {
      spotMeasurement.pop();
    }
  }

  if (point.length > 1) {
    for (var i = point.length; i > 0; i--) {
      point.pop();
    }
  }

  scene.remove(line);

  spotMeasurement.push(
    JSON.parse(
      JSON.stringify({
        panorama: pano_key,
        type: 2,
        img_lon: img_lon,
        img_lat: img_lat,
        lon: spotLocation_wgs84[0],
        lat: spotLocation_wgs84[1],
        utm_x: cursor_x,
        utm_y: cursor_y,
        utm_code: utm_code,
        utm_srid: utm_srid,
      })
    )
  );

  restoreSpotMeasurement();
  /*
  const point = new Array();
  point.push(intersect[0].point);
  point.push(intersect[0].point.clone());

  const geometry = new THREE.BufferGeometry().setFromPoints(point);
  const line = new THREE.LineSegments(
    geometry,
    new THREE.LineBasicMaterial({
      color: "black",
    })
  );

  scene.add(line);*/
};

let removeImgObjs = function (type) {
  switch (type) {
    case 1:
      for (let i = panoTagsGroup.children.length - 1; i >= 0; i--) {
        panoTagsGroup.remove(panoTagsGroup.children[i]);
      }

      scene.remove(panoTagsGroup);
      break;
    case 2:
      for (let i = spotGroup.children.length - 1; i >= 0; i--) {
        spotGroup.remove(spotGroup.children[i]);
      }

      scene.remove(spotGroup);
      break;
  }
};

let restoreImgObjs = function (type) {
  const convert2d3d = function (r, x, y) {
    let lat = y * Math.PI - Math.PI / 2;
    let long = x * 2 * Math.PI - Math.PI;

    return {
      x: r * Math.cos(lat) * Math.cos(long),
      y: r * Math.sin(lat),
      z: -r * Math.cos(lat) * Math.sin(long),
    };
  };

  let retrieve_url;
  switch (type) {
    case 1:
      retrieve_url = url_backend + "/image_objects/?type=" + type.toString() + "&panorama=" + pano_key;
      break;
    case 2:
      retrieve_url = url_backend + "/image_objects/?type=" + type.toString();
      break;
  }

  const component = this;
  let objauthor = new Object();

  objauthor.Accept = "application/json";
  objauthor.Authorization = "Bearer " + getVt();

  $.ajax({
    type: "GET",
    url: retrieve_url,
    headers: objauthor,
    error: function (errormsg) {
      console.log("ERROR", errormsg);
    },
    success: function (resultData) {
      let startVertex;
      for (let i = 0; i < resultData["results"].length; ++i) {
        const item = resultData["results"][i];
        let newTagObject;
        switch (type) {
          case 1:
            const tag_geom = JSON.parse(item["geom_on_panorama"]);
            const tagShape = new THREE.BufferGeometry();
            let vector = [];
            for (let v = 0; v < tag_geom.length; ++v) {
              const vertex = convert2d3d(450, tag_geom[v][0], tag_geom[v][1]);
              if (!startVertex) {
                startVertex = new THREE.Vector3(vertex.x, vertex.y, vertex.z);
              }
              vector.push(new THREE.Vector3(vertex.x, vertex.y, vertex.z));
            }

            vector.push(startVertex);
            newTagObject = new THREE.Line(tagShape.setFromPoints(vector), component.loadedtagsMaterial);
            panoTagsGroup.add(newTagObject);

            newTagObject.ws_type = "tag";
            if (bool_labels) {
              newTagObject.label = createLabel(type, item.note);
            }

            newTagObject.toScreenXY = function () {
              geometry.computeBoundingBox();
              let pos = new THREE.Vector3();

              geometry.boundingBox.getCenter(pos);
              let projScreenMat = new THREE.Matrix4();

              projScreenMat.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
              let frustum = new THREE.Frustum();
              frustum.setFromMatrix(projScreenMat);

              if (frustum.containsPoint(pos)) {
                pos.applyMatrix4(projScreenMat);
                return {
                  x: ((pos.x + 1) * container.clientWidth) / 2 + 0,
                  y: ((-pos.y + 1) * container.clientHeight) / 2 + 0,
                };
              } else {
                return {
                  x: -100,
                  y: -100,
                };
              }
            };

            newTagObject.refreshLabel = function () {
              const screenPosition = toScreenXY();
              label.style.left = screenPosition.x + "px";
              label.style.top = screenPosition.y + "px";
            };

            break;
          case 2:
            newTagObject = spotProto.clone();
            newTagObject.position.set(item["utm_y"] - utm_y, -height_from_ground, item["utm_x"] - utm_x);
            newTagObject.children[0].ws_type = "map spot";
            newTagObject.children[0].ws_imgObjKey = item.id;
            newTagObject.children[1].ws_type = "map spot";
            newTagObject.children[1].ws_imgObjKey = item.id;

            spotGroup.add(newTagObject);
            break;
        }

        startVertex = undefined;
      }

      switch (type) {
        case 1:
          scene.add(panoTagsGroup);
          break;
        case 2:
          scene.add(spotGroup);
          break;
      }
    },
  });
};

let restoreSpotMeasurement = function (bool = true) {
  removeImgObjMeasurement();
  if (bool) {
    restoreImgObjMeasurement();
  }
};

let restoreImgObjMeasurement = function (type) {
  const convert2d3d = function (r, x, y) {
    let lat = y * Math.PI - Math.PI / 2;
    let long = x * 2 * Math.PI - Math.PI;

    return {
      x: r * Math.cos(lat) * Math.cos(long),
      y: r * Math.sin(lat),
      z: -r * Math.cos(lat) * Math.sin(long),
    };
  };

  let objMeasurements = new Object();
  let featuresMeasurement = new Array();

  spotMeasurement.forEach(function (item) {
    let newTagObjectMeasurement;
    let geometryMeasurement = new Object();

    newTagObjectMeasurement = spotProtoMeasurement.clone();
    newTagObjectMeasurement.position.set(item["utm_y"] - utm_y, -height_from_ground, item["utm_x"] - utm_x);
    newTagObjectMeasurement.children[0].ws_type = "measurement";
    newTagObjectMeasurement.children[0].ws_imgObjKey = item.id;
    newTagObjectMeasurement.children[1].ws_type = "measurement";
    newTagObjectMeasurement.children[1].ws_imgObjKey = item.id;

    spotGroupMeasurement.add(newTagObjectMeasurement);
    scene.add(spotGroupMeasurement);

    geometryMeasurement.type = "Point";
    geometryMeasurement.coordinates = new Array(item["lon"], item["lat"]);
    featuresMeasurement.push(geometryMeasurement);
  });

  if (spotGroupMeasurement.children.length === 2) {
    point.push(spotGroupMeasurement.children[0].position);
    point.push(spotGroupMeasurement.children[1].position);
    const geometry = new THREE.BufferGeometry().setFromPoints(point);
    line = new THREE.LineSegments(
      geometry,
      new THREE.LineBasicMaterial({
        color: 0xffffff,
        linewidth: 10,
      })
    );
    scene.add(line);
  }

  objMeasurements.type = "FeatureCollection";
  objMeasurements.features = featuresMeasurement;

  mapSpotsLoadedMeasurement(objMeasurements);
};

export let spotLayerMeasurement = new VectorLayer({
  title: "Measurement",
  visible: true,
  source: new VectorSource({
    format: new GeoJSON(),
  }),
  style: new Style({
    stroke: new Stroke({
      color: "blue",
      width: 1,
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.3)",
    }),
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: "fraction",
      anchorYUnits: "fraction",
      src: "/pointer/sprites/point9.png",
    }),
  }),
});

export const removeSpotMeasurement = function () {
  if (spotMeasurement.length > 1) {
    for (var i = spotMeasurement.length; i > 0; i--) {
      spotMeasurement.pop();
    }
  }

  restoreSpotMeasurement();
};

let mapSpotsLoadedMeasurement = function (spotMeasurement, intersect) {
  const features = new GeoJSON().readFeatures(spotMeasurement, {
    featureProjection: "EPSG:3857",
  });

  if (spotMeasurement.features.length == 2) {
    let arrMesurement = [];
    let lonArr = [];
    let latArr = [];

    arrMesurement = spotMeasurement.features;
    arrMesurement.forEach(function (item, index) {
      for (let i = 0; i <= 1; i++) {
        if (i == 0) {
          lonArr.push(item.coordinates[0]);
        } else {
          latArr.push(item.coordinates[1]);
        }
      }
    });

    let measureDistance = getDistanceFromLatLonInMetres(latArr[0], lonArr[0], latArr[1], lonArr[1]);
    measureDistance1.value = measureDistance.toFixed(2);
    console.log(measureDistance1.value);

    $("#distance").toast("show");
  }

  const spotSourceMeasurement = new VectorSource({
    features: features,
    format: new GeoJSON(),
  });

  spotLayerMeasurement.setSource(spotSourceMeasurement);
};

export const removeImgObjMeasurement = function () {
  for (let i = spotGroupMeasurement.children.length - 1; i >= 0; i--) {
    spotGroupMeasurement.remove(spotGroupMeasurement.children[i]);
  }

  if (arrSpotMeasurement > 1) {
    let originalLength = spotMeasurement.length;
    for (var i = originalLength; i > 0; i--) {
      spotMeasurement.pop();
    }
  }
  scene.remove(spotGroupMeasurement);
  scene.remove(line);
};
